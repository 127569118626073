import googleLogo from "Images/connected_accounts/google.png";
import githubLogo from "Images/connected_accounts/github.png";
import bitbucketLogo from "Images/connected_accounts/bitbucket.png";
import gitlabLogo from "Images/connected_accounts/gitlab.png";

export const PROVIDERS = [
  { label: "GitHub", name: "github", image: githubLogo },
  { label: "Bitbucket", name: "bitbucket", image: bitbucketLogo },
  { label: "Google", name: "google", image: googleLogo },
  { label: "GitLab", name: "gitlab", image: gitlabLogo }
];
