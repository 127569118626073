import React from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";

import useDecodedParams from "Hooks/useDecodedParams";

import DetailPage from "./detail/Page";
import SettingsRoutes from "./settings/Routes";
import ActionsRoutes from "./actions/Routes";
import ServicesRoutes from "./services/Routes";
import BackupsPage from "./backups/Page";
import MetricsPage from "./metrics/Page";

import Page from "./Page";
import PluginApp from "../app/Page";

const Routes = () => {
  const { path, url } = useRouteMatch();
  const { organizationId, projectId, environmentId } = useDecodedParams();

  return (
    <Page>
      <Switch>
        <Route path={`${path}/metrics`}>
          <MetricsPage />
        </Route>
        <Route path={`${path}/backups`}>
          <BackupsPage />
        </Route>
        <Route path={`${path}/actions`}>
          <DetailPage>
            <ActionsRoutes />
          </DetailPage>
        </Route>
        <Route path={`${path}/settings`}>
          <SettingsRoutes />
        </Route>
        <Route exact path={`${path}/services`}>
          <Redirect to={`${url}/services/routes`} />
        </Route>
        <Route path={`${path}/services/:appName?`}>
          <ServicesRoutes />
        </Route>
        <Route path={`${path}/-/app/:appId`}>
          <PluginApp
            route="/:organizationId/:projectId/:environmentId/"
            type="tab"
            organizationId={organizationId}
            projectId={projectId}
            environmentId={environmentId}
          />
        </Route>
        <Route path={[`${path}/log/:activityId`, path]}>
          <DetailPage />
        </Route>
      </Switch>
    </Page>
  );
};

export default Routes;
