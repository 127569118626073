import styled, { css } from "styled-components";

import { semiBoldAlias, themeHelpers } from "Libs/theme";

import { Button } from "@platformsh/ui-kit";

export const Layout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0;
  color: ${props => props.theme.sectionText};

  a {
    color: ${props => props.theme.links};
    text-decoration: none;
    font-size: 14px;
    &.button {
      color: #fff;
      padding: 0 16px;
      font-size: 15px;
    }
  }
`;

export const GridWrapper = styled.div`
  width: 100%;
  margin-top: 0 !important;
  padding: 22px 0 32px 0;
  .grid {
    color: ${props => props.theme.sectionText};
    margin: 0 -32px;

    &:not(:last-child) {
      margin-bottom: 18px;
    }
    display: flex;
    background: transparent;
    > div {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
    .number {
      font-size: 24px;
    }
    .label {
      margin-bottom: 0;
    }
  }
`;

export const ItemWrapper = styled.div`
  width: 100%;
  .label {
    margin-top: 0;
    margin-bottom: 16px;
  }
  .subtext {
    margin-top: 0;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  h2 {
    margin-bottom: 0;
    line-height: 32px;
    &:first-letter {
      text-transform: none;
    }
  }
`;

export const Env = styled.div`
  margin-top: 2px;
  ${semiBoldAlias};
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.envLabel};
`;

export const Wizard = styled.div`
  display: flex;
  margin: 16px 0 0 0;

  ${Button} {
    font-size: 13px;
    ${semiBoldAlias};
    line-height: 28px;
    height: 28px;
    border: none;
    svg {
      margin: 0 4px 0 0;
      vertical-align: middle;
      fill: var(--link);
    }

    &:hover {
      &:after {
        content: none;
      }
      svg {
        fill: var(--link-visited);
      }
    }
  }
`;

export const Live = styled.span`
  display: inline-block;
  background: ${props =>
    props.theme.name === "dark"
      ? themeHelpers(props.theme, "sectionColor")
      : themeHelpers(props.theme, "linkTint")};
  color: ${props =>
    props.theme
      ? props => themeHelpers(props.theme, "buttonColor")
      : "#4786ff"};
  font-size: 12px;
  line-height: 24px;
  height: 24px;
  padding: 0 8px;
  ${semiBoldAlias()};
  border-radius: 2px;
`;

export const Time = styled.div`
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 8px;
  position: relative;
  ${semiBoldAlias};
`;

export const Status = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 15px;
  line-height: 18px;
  ${semiBoldAlias};
  text-transform: capitalize;

  &::after {
    content: '';
    display: inline-block;
    margin-left: 12px;
    height: 6px;
    width: 6px;
    border-radius: 6px;
    background: ${props => {
      switch (props.status) {
        case "active":
          return props.theme.success;
        case "dirty":
        case "disabled":
          return "#ff8359";
        case "inactive":
          return "#98a0ab";
        default:
          return props.theme.success;
      }
    }};
`;

export const ExternalEnvLink = styled.span`
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
`;

export const Label = styled.div`
  display: flex;
  font-size: 13px;
  line-height: 16px;
  color: ${props => props.theme.envLabel};
  align-items: center;
  ${semiBoldAlias};
  .info-dialog {
    margin-bottom: -2px;
  }
`;

export const RegionSuffix = styled.span`
  color: ${props => (props.theme.name === "contrast" ? "#53585f" : "#98a0ab")};
  margin-left: 7px;
  font-size: 14px;
  line-height: 0.8;
`;

export const Value = styled.div`
  font-size: 15px;
  line-height: 22px;
  margin-bottom: 4px;
  ${semiBoldAlias};
`;

export const EnvUrl = styled.div`
  display: flex;
  width: calc(100% + 48px);
  height: 48px;
  box-sizing: border-box;
  margin: 0 -24px -24px -24px;
  padding: 12px 16px;
  border-radius: 2px;
  background: ${props =>
    props.theme.name === "dark"
      ? themeHelpers(props.theme, "sectionColor")
      : themeHelpers(props.theme, "linkTint")};

  svg {
    min-width: 24px;
    path {
      fill: ${props =>
        props.theme.name === "contrast"
          ? props.theme.codeText
          : props.theme.links};
    }
  }
  > a {
    white-space: nowrap;
    display: inline-block;
    padding: 0 8px;
    max-width: calc(100% - 32px);
    text-decoration: none;
    position: relative;
    ${props =>
      props.theme.name === "contrast" && `color: ${props.theme.codeText};`};
    &:hover:after {
      position: absolute;
      display: inline-block;
      content: "";
      width: calc(100% - 16px);
      height: 1px;
      background: ${props => props.theme.links};
      bottom: 0;
      left: 8px;
    }
    &:focus {
      outline: none;
      border: 1px solid ${props => themeHelpers(props.theme, "buttonHover")};
      border-radius: 4px;
      box-shadow: 0 0 2px ${props => themeHelpers(props.theme, "buttonHover")};
      text-decoration: none;
      margin: -1px;
    }
    &:active {
      border: none;
      box-shadow: none;
      text-decoration: underline;
      margin: 0;
    }
  }
  ${props =>
    props.status === "inactive" &&
    css`
      background: ${props =>
        props.theme.name === "dark"
          ? props.theme.dark
          : "rgba(201, 208, 228, 0.5)"};
      color: ${props => props.theme.subNavText};
      font-size: 13px;
      ${semiBoldAlias};
      svg {
        margin-right: 16px;
        path {
          fill: ${props => props.theme.subNavText};
        }
      }
    `};
`;

export const Truncate = styled.div`
  flex: 1;
  margin-left: 8px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: ${props => props.theme.primary};
  line-height: 24px;
  > div {
    min-width: 0;
    display: unset;
  }
  a {
    display: unset;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
