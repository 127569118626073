import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormattedMessage, useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";

import { Checkbox } from "@platformsh/ui-kit";

import ModalWrapper from "Components/Modal";
import Heading3 from "Components/styleguide/Heading3";
import Button from "Components/Button";
import ButtonWrapper from "Components/ButtonWrapper";
import InputField from "Components/fields/InputField";
import Tag from "Components/Tag";
import { PERMISSIONS } from "Constants/constants";
import InfoDialog from "Components/InfoDialog";
import Error from "Components/Error";

import {
  setInvitations,
  setPermissions
} from "Reducers/organization/invitation";

import { DOCS_ORGANIZATIONS } from "Constants/documentationUrls";

import * as S from "./styles";

const MAX_TAG_NUMBER = 10;

const AddMemberModal = ({
  closeModal,
  isOpen,
  addMembers,
  fieldsErrors,
  errorMessage
}) => {
  const [input, setInput] = useState("");
  const intl = useIntl();

  const dispatch = useDispatch();
  const invitations = useSelector(state =>
    state.organizationInvitation.get("invitations", [])
  );
  const permissions = useSelector(state =>
    state.organizationInvitation.get("permissions", [])
  );

  const onPermissionChange = (e, value) => {
    const index = permissions.indexOf(value);
    if (e.target.checked && index === -1) {
      permissions.push(value);
    } else if (!e.target.checked && index !== -1) {
      permissions.splice(index, 1);
    }

    dispatch(setPermissions([...permissions]));
  };

  const validateInput = () => {
    const newList = [
      ...invitations,
      ...input.split(",").map(email => ({
        email: email.trim(),
        permissions
      }))
    ].filter((v, i, a) => a.findIndex(invit => invit.email === v.email) === i);

    if (!newList.length) {
      return false;
    }

    dispatch(setInvitations(newList));
    setInput("");

    return newList;
  };

  const onKeyPress = e => {
    if (e.key === "Enter" || e.key === ",") {
      e.preventDefault();
      e.stopPropagation();
      validateInput();
    }
  };

  const removeTag = email => {
    dispatch(
      setInvitations(invitations.filter(invit => invit.email !== email))
    );
  };

  return (
    <ModalWrapper
      modalClass={`modal-medium`}
      isOpen={isOpen}
      closeModal={closeModal}
    >
      <S.ModalBodyWrapper>
        <Heading3>
          {intl.formatMessage({ id: "organizations.invite_user(s)" })}
        </Heading3>
        <S.InformationText>
          {intl.formatMessage({ id: "add_user_organization" })}
        </S.InformationText>
        {errorMessage && <Error>{errorMessage}</Error>}
        <InputField
          width="100%"
          id="email"
          name="email"
          label=""
          placeholder={intl.formatMessage({ id: "members_email_address" })}
          value={input}
          onChange={e => setInput(e.target.value)}
          handleKeyPress={onKeyPress}
          required={false}
          error={fieldsErrors?.email}
        />
        <S.LabelWithInfo>
          <S.CustomLabel>
            <FormattedMessage id="organizations.permissions" />
          </S.CustomLabel>
          <InfoDialog
            text={
              <FormattedMessage
                id="organizations.add_modal.info"
                values={{
                  documentationLink: (
                    <a href={DOCS_ORGANIZATIONS}>documentation</a>
                  )
                }}
              />
            }
          />
        </S.LabelWithInfo>
        {PERMISSIONS.map((p, i) => (
          <S.CheckBoxLabel key={p.value}>
            <Checkbox
              id={p.value}
              tabIndex={i}
              checked={permissions.find(val => val === p.value) ? true : false}
              onChange={e => onPermissionChange(e, p.value)}
            />
            {p.label}
          </S.CheckBoxLabel>
        ))}

        {invitations.length ? (
          <div>
            <S.UsersLabel>
              {invitations.length} {intl.formatMessage({ id: "users" })}
            </S.UsersLabel>
            <S.TagGrid>
              {invitations.slice(0, MAX_TAG_NUMBER).map(invitation => (
                <Tag
                  key={invitation?.email}
                  text={invitation?.email}
                  onClose={() => removeTag(invitation?.email)}
                >
                  <S.Email>{invitation?.email}</S.Email>
                </Tag>
              ))}
              {invitations.length > MAX_TAG_NUMBER + 1 ? (
                <S.ExtraTag>+{invitations.length - MAX_TAG_NUMBER}</S.ExtraTag>
              ) : null}
            </S.TagGrid>
          </div>
        ) : null}

        <ButtonWrapper className="modal-buttons">
          <Button
            id={`add-billing-modal-button`}
            type="submit"
            aria-label={intl.formatMessage({ id: "invite" })}
            onClick={() => {
              const invits = validateInput();
              addMembers({ invitations: invits, permissions });
            }}
            disabled={!invitations.length && !input}
          >
            {intl.formatMessage({ id: "invite" })}
          </Button>
          <Button
            type="button"
            className="secondary"
            aria-label={intl.formatMessage({ id: "cancel" })}
            onClick={closeModal}
          >
            {intl.formatMessage({ id: "cancel" })}
          </Button>
        </ButtonWrapper>
      </S.ModalBodyWrapper>
    </ModalWrapper>
  );
};

AddMemberModal.propTypes = {
  closeModal: PropTypes.func,
  addMembers: PropTypes.func,
  fieldsErrors: PropTypes.object,
  errorMessage: PropTypes.string,
  isOpen: PropTypes.bool
};

export default AddMemberModal;
