import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { Map } from "immutable";
import { LiveMessage } from "react-aria-live";

import { subscriptionSelector, loadSubscription } from "Reducers/subscription";
import { deploymentSelectors } from "Reducers/environment/deployment";
import { loadProjectWizard } from "Reducers/project/wizard";

import ActivitySection from "../../../../common/containers/ActivitySection";
import ContentLayout from "Components/ContentLayout";
import ListGroup from "Components/ListGroup";
import { ServicesDisplay } from "../../../../common/containers/ServicesDisplay";
import Heading2 from "Components/styleguide/Heading2";
import EnvironmentsTree, {
  EnvironmentsTreeSkeleton
} from "Containers/EnvironmentsTree";
import ErrorBoundary from "Components/ErrorBoundary";
import PageMeta from "Components/PageMeta";
import ContentWizard from "../ContentWizard";
import InfoDropButtons from "../../../../common/containers/InfoDropButtons";

import { ProjectDetails } from "../../components";
import ProjectOverviewSkeleton from "./ProjectOverviewSkeleton";
import ServicesDisplaySkeleton from "../../../../common/components/ServicesDisplaySkeleton";

import * as S from "./styles";

const ProjectOverview = ({ organizationId, projectId }) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [mainEnvironment, setMainEnv] = useState();
  const { currentDeployment } = deploymentSelectors;
  const [areServicesReady, setAreServicesReady] = useState(false);

  const user = useSelector(state => state.app?.get("me").toJS());

  const project = useSelector(state => {
    return state.project?.getIn(["data", organizationId, projectId], {});
  });

  const deployment = useSelector(state =>
    currentDeployment(state, {
      organizationId,
      projectId
    })
  );
  const isLoadingProject = useSelector(({ project }) =>
    project?.get("loading")
  );

  const environments = useSelector(state => {
    return state.environment
      ?.getIn(["data", organizationId, projectId], Map())
      .valueSeq();
  });

  const subscription = useSelector(state => {
    return subscriptionSelector(state, {
      organizationId,
      projectId,
      id: project?.subscription_id
    });
  });

  const activities = useSelector(state => {
    return state.activity?.getIn(
      ["byProject", "data", organizationId, projectId],
      Map()
    );
  });

  useEffect(
    () => {
      if (typeof project?.getSubscriptionId === "function") {
        dispatch(
          loadSubscription({
            organizationId,
            projectId,
            id: project?.getSubscriptionId()
          })
        );
      }
    },
    [project, deployment?.id]
  );

  useEffect(
    () => {
      let mainEnv = "";
      if (environments?.size) {
        mainEnv = environments.find(env => env.is_main);
      }

      setMainEnv(mainEnv);
    },
    [environments?.size]
  );

  useEffect(
    () => {
      if (!subscription?.id || !project?.id) return;
      dispatch(
        loadProjectWizard({
          user,
          organizationId,
          projectId,
          hasCode
        })
      );
    },
    [subscription?.id, activities?.size, project?.id]
  );

  const hasCode = useMemo(
    () =>
      typeof mainEnvironment?.hasPermission === "function" &&
      mainEnvironment.hasPermission("#initialize"),
    [mainEnvironment]
  );

  return (
    <ContentLayout>
      <PageMeta title={project && project.title} />
      <ErrorBoundary>
        <LiveMessage
          message={`${project ? project.title : "Project"} overview`}
          aria-live="polite"
        />
        <InfoDropButtons className="buttons" project={project} />
        <S.ColumnWrapper>
          <aside className="col-4">
            <ListGroup
              className="project-info"
              aria-labelledby="project-heading"
            >
              {isLoadingProject ? (
                <ProjectOverviewSkeleton />
              ) : (
                <S.BoxLayout>{project && <ProjectDetails />}</S.BoxLayout>
              )}
            </ListGroup>

            <ListGroup
              aria-labelledby="services-heading"
              style={{ position: "relative" }}
            >
              {!areServicesReady && <ServicesDisplaySkeleton />}
              <S.BoxLayout className="services">
                <Heading2 id="services-heading">
                  {intl.formatMessage({ id: "apps_services" })}
                </Heading2>
                <S.RowLayout>
                  <ServicesDisplay
                    height="250px"
                    minHeight="250px"
                    maxHeight={220}
                    mainEnvironmentId={mainEnvironment?.id}
                    display="project"
                    treePositionY={10}
                    hasInfoTooltip
                    projectId={projectId}
                    organizationId={organizationId}
                    onLoadEnd={() => setAreServicesReady(true)}
                  />
                </S.RowLayout>
              </S.BoxLayout>
            </ListGroup>
          </aside>

          <div>
            <ContentWizard project={project} user={user} />

            <ListGroup aria-labelledby="environments-heading">
              {environments.size ? (
                <S.BoxLayout
                  className={`environments${hasCode ? " no-code" : ""}`}
                >
                  <Heading2 id="environments-heading">
                    {intl.formatMessage({ id: "environments" })}
                  </Heading2>
                  <div>
                    <EnvironmentsTree
                      projectId={projectId}
                      organizationId={organizationId}
                    />
                  </div>
                </S.BoxLayout>
              ) : (
                <EnvironmentsTreeSkeleton />
              )}
            </ListGroup>

            <ActivitySection context="project" />
          </div>
        </S.ColumnWrapper>
      </ErrorBoundary>
    </ContentLayout>
  );
};

ProjectOverview.propTypes = {
  projectId: PropTypes.string.isRequired,
  organizationId: PropTypes.string.isRequired
};

export default ProjectOverview;
