import React from "react";
import { Route, Switch, useRouteMatch, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import useDecodedParams from "Hooks/useDecodedParams";
import SetupRoutes from "./setup/Routes";
import ProjectRoutes from "./project/Routes";
import ProjectsRoutes from "./organizationProjects/Routes";
import SettingsRoutes from "./settings/Routes";
import OrganizationError from "../common/containers/OrganizationError";
import BillingRoutes from "./billing/Routes";

import { organizationByDescriptionIdSelector } from "Reducers/organization";

const Routes = () => {
  const { path } = useRouteMatch();
  const { organizationId } = useDecodedParams();

  const organization = useSelector(state =>
    organizationByDescriptionIdSelector(state, {
      organizationDescriptionId: organizationId
    })
  );

  return (
    <OrganizationError>
      <Switch>
        <Route path={`${path}/-/settings`}>
          {process.env.ENABLE_ORGANIZATION ? (
            <SettingsRoutes />
          ) : (
            <Redirect to="/" />
          )}
        </Route>
        <Route path={`${path}/-/billing`}>
          {process.env.ENABLE_ORGANIZATION &&
          ((!organization || organization?.hasLink("orders")) &&
            process.env.ENABLE_BILLING) ? (
            <BillingRoutes />
          ) : (
            <Redirect to="/" />
          )}
        </Route>
        <Route path={`${path}?/create-project/:step?`}>
          <SetupRoutes />
        </Route>
        <Route path={`${path}/:projectId`}>
          <ProjectRoutes />
        </Route>
        <Route path={path}>
          {process.env.ENABLE_ORGANIZATION ? (
            <ProjectsRoutes />
          ) : (
            <Redirect to="/" />
          )}
        </Route>
      </Switch>
    </OrganizationError>
  );
};

export default Routes;
